import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import NavbarTop from "../components/navbar-top"

import Compliance from "../sections/compliance"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Compliance" />
    <NavbarTop />
    <PageHeader title="Compliance" bg={data.file.childImageSharp.fluid}/>
    <Compliance />
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "compliance.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
