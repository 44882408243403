import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "//compliance.md/" }) {
        html
        excerpt
      }
    }
  `)
  const { markdownRemark } = data

  return (
    <section
      id="compliance"
      className="container-xl"
      style={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
        minHeight: `30vh`,
      }}
    >
      <div
        style={{
          maxWidth: "900px",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
      </div>
    </section>
  )
}
